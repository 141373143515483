<template>
  <v-container fluid v-if="projectData">
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Categories Of Projects</div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="projectData && projectData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="projectData"
              hide-default-footer
            >
              <template v-slot:[`item.project_name`]="{ item }">
                <router-link
                  :to="{
                    name: 'projectDetails',
                    params: { id: item.id },
                  }"
                >
                  {{ item.project_name }}
                </router-link>
              </template>
            </v-data-table>
          </div>
        </section>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
export default {
  data: () => ({
    pagination: {
      current_page: 1,
    },
    isLoading: false,
    headers: [
      { text: "Project Name", value: "project_name" },
      { text: "Client Name", value: "client_name" },
      { text: "Project Country", value: "project_country" },
      { text: "Timeline", value: "project_timeline" },
      { text: "importance", value: "importance" },
      { text: "Sales", value: "user.name" },
    ],
    projectData: [],
  }),
  components: { EmptyState },
  computed: {},
  methods: {
    // goToPage() {
    //   this.getProjectData();
    //   window.scrollTo(0, 0);
    // },
    getProjectData() {
      this.projectData = [];
      this.isLoading = true;
      apiServices
        .get(`categories?category_id=${this.$route.params.id}`)
        .then((res) => {
          if (res) {
            if (res && res.data && res.data.projects) {
              this.isLoadingSearch = false;
              this.projectData = res.data.projects;
              console.log("projectData", this.projectData);
            }
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
  },
  created() {
    this.getProjectData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped></style>
